<template>
    <div :class="rootClasses">
        <div class="cs-banner-background" :style="backgroundStyle">
            <div class="cs-banner-background-filler">
                <div v-if="!data.video" class="cs-banner-background-filler-image-background">
                    <img  :src="data.backgroundImage" :alt="data.imgAlt">
                </div>
                <div v-else class="cs-banner-background-filler-video-background">
                    <video  autoplay="" loop="" muted="" :src="data.video" playsinline=""></video>
                </div>
                <div v-if="!data.video" class="cs-banner-background-filler-colors">
                    <div class="cs-banner-background-filler-colors-top"></div>
                    <div class="cs-banner-background-filler-colors-bottom"></div>
                </div>
            </div>
        </div>
        <div class="cs-banner-content">
            <div class="container">
                <div class="cs-banner-content-image">
                    <img :src="data.image" :alt="data.imgAlt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    components: {},
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {}
    },
    computed: {
        rootClasses() {
            return {
                ['cs-banner']: true,
                ['theme-block']: true,
                ['has-background']: true
            }
        },
        backgroundStyle() {
            return {
                ['background-size']: 'cover',
                ['background-repeat']: 'no-repeat'
            }
        }
    },
    methods: {
        linkClass(link) {
            if (link.outline) return 'outline'

            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';

$banner_height: 70vh;
$link_top_margin: $default_margin * 2;

.cs-banner {
    background-color: $theme_bg_color;
    // height: $banner_height;
    width: 100%;
    position: relative;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    &-background {
        pointer-events: none;
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;

        &-filler {

            width: 100%;
            height: 100%;
            background-color: #000;
            // opacity: 0.4;
            pointer-events: none;
            overflow: hidden;

            &-image-background {
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-video-background {
                height: 100%;
                overflow: hidden;
                display: flex;
                transform: scale(1.865);
                video {
                    display: block;
                    position: relative;
                    z-index: 1;
                    max-width: 100%;
                    width: 100%;
                    flex: 0 0 100%;
                }
            }

            &-colors {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;

                &-top,
                &-bottom {
                    height: 50%;
                    width: 100%;
                    background-color: red;
                    opacity: 0.5;
                }

                &-top {
                    background-color: $banner_first_color;
                }

                &-bottom {
                    background-color: $banner_second_color;
                }
            }
        }
    }

    &-content {
        position: relative;
        z-index: 2;

        &-image {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

}
</style>
